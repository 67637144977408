<template>
  <page-header-wrapper>
    <a-card :bordered="false">
      <a-card title="Seting Top Banner Style:">
        <a-tabs type="card" v-model="cardBannarValue">
          <a-tab-pane key="0" tab="Picture">
<!--            <a-button type="primary">Upload Picture</a-button>-->
            <a-upload
              name="file"
              :multiple="true"
              :show-upload-list="false"
              :action="tupianshangchuanDizhi"
              :headers="headers"
              @change="handleChange($event)"
            >
              <a-button type="primary" style="margin-right: 10px;">Upload Picture</a-button>
            </a-upload>
<!--{{getStyleData.bannerUrl}}-->
            <div style="display:flex;flex-wrap: wrap;margin-top: 10px;" v-if="getStyleData.bannerUrl">
              <div style="display: flex;align-items: center; justify-content: center">
                <img :src="getStyleData.bannerUrl"  style="width: 180px;height: 52px;" />
              </div>
            </div>
            <div style="margin-bottom: 20px;margin-top: 20px;">
              Recommended size of picture: 180 px * 52 px, supporting JPG and PNG formats.
            </div>

<!--            <img style="width: 100%;height: 520px;" :src="getStyleData.bannerUrl" />-->
          </a-tab-pane>

          <a-tab-pane key="1" tab="Color">
            <div style="width: 100%;height: 300px;">
              <elColorPicker :value="getStyleData.bannerColor" @change="handlerColorPickerChange" />
            </div>
          </a-tab-pane>
        </a-tabs>
      </a-card>
      <a-card title="Set Buttion Style:" style="margin-top: 20px">
        <a-tabs type="card">
          <a-tab-pane key="1" tab="Color">
            <div style="width: 100%;height: 300px;">
              <elColorPicker :value="getStyleData.btnColor" @change="handlerColorPickerChange" />
            </div>
          </a-tab-pane>
        </a-tabs>
      </a-card>
      <a-card title="Set Curves Style:"  style="margin-top: 20px">
        <a-tabs type="card">
          <a-tab-pane key="1" tab="Color">
            <div style="width: 100%;height: 300px;">
              <elColorPicker :value="getStyleData.curvesColor" @change="handlerColorPickerChange" />
            </div>
          </a-tab-pane>
        </a-tabs>
      </a-card>

      <a-card title="Set Ingredients&Terpenes Style:"  style="margin-top: 20px;">
        <a-select v-model="getStyleData.curvesTemplate" placeholer="Select" style="width: 200px;">
        </a-select>
      </a-card>

      <a-button type="primary" style="margin-top: 20px;" @click="clickPrimary">
        Save Setings
      </a-button>
    </a-card>

  </page-header-wrapper>
</template>

<script>
import {pageStyle, listStyle, delStyle, getStyle, addStyle} from '@/api/cigarette/style'
import elColorPicker from 'el-color-picker'
import {tupianshangchuanDizhi} from "@/utils/request";

export default {
  name: 'BrandStyle',
  components: {
    elColorPicker
  },
  mixins: [],
  data () {
    return {
      tupianshangchuanDizhi:tupianshangchuanDizhi,
      curvesTemplate: 1,
      getStyleData: {
        bannerUrl: "",
        bannerColor: "",
        btnColor: "",
        curvesTemplate: "",
        curvesColor: "",
        cardBannarValue:"0"
      },
      headers:{},
      cardBannarValue:"0"
    }
  },
  filters: {
  },
  created () {
    this.headers = {
      "Authorization" : "Bearer " + this.$store.getters.token
    }
    this.detailsData();
  },
  computed: {
  },
  watch: {
  },
  methods: {
    handleChange(e,item) {
      console.log(e)
      console.log(item);
      this.getStyleData.bannerUrl = e.file.response.data
      // e.file.response.data
    },
    detailsData() {
      getStyle().then(res => {
        this.getStyleData = res.data;
      })
    },
    handlerColorPickerChange(e) {
      console.log(e);
      this.color = e;
    },
    clickPrimary() {
      this.getStyleData.cardBannarValue = this.cardBannarValue;
      addStyle(this.getStyleData).then(res => {
        this.$message.success("success");
      })
    },
  }
}
</script>
